import { RouteLocationNormalized, RouterScrollBehavior } from 'vue-router'
import { useHistoryStateStore } from '@/modules/base/stores/useHistoryStateStore'

// * Scroll to the saved position of already visited routes (only when going back or reloading)
export const handleSavedPosition = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  savedPosition: Parameters<RouterScrollBehavior>['2']
) => {
  return savedPosition ?? clearHistoryState(to, from)
}

// * Clean history store for the current route if outside navigation doesn't have `savedPosition`
const clearHistoryState = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) => {
  if (
    to.meta.historyStateKey &&
    to.meta.historyStateKey !== from.meta.historyStateKey
  ) {
    const historyStateStore = useHistoryStateStore()
    historyStateStore.deleteHistoryState(to.meta.historyStateKey)
  }
}
